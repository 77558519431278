var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"assessment__results --loading"},[_c('Spinner')],1):[(_vm.isResultReleased && _vm.hasAnyResult)?_c('transition-group',{staticClass:"assessment__results assessment__results--final",attrs:{"appear":"","name":"card","tag":"div"}},_vm._l((_vm.examFinalResults),function(examFinalResult){return _c('AssessmentResultsExamFinal',{key:examFinalResult.id,attrs:{"color":examFinalResult.color,"image":examFinalResult.image,"title":examFinalResult.name},nativeOn:{"click":function($event){return _vm.$router.push({
          name: 'examResultGeneral',
          params: {
            applicationId: _vm.applicationId,
            assessmentId: _vm.assessmentId,
            examId: examFinalResult.id,
          },
        })}}})}),1):_c('transition-group',{staticClass:"assessment__results",attrs:{"appear":"","name":"fade","tag":"div"}},[_vm._l((_vm.examsWithoutResults),function(exam){return _c('div',{key:exam.id,attrs:{"id":"v-step-3"}},[_c('AssessmentResultsExamEmpty',{attrs:{"exam":exam},on:{"click":function($event){return _vm.showExamModal(exam)}}})],1)}),_vm._l((_vm.examPartialResults),function(examPartialResult){return _c('AssessmentResultsExamPartial',{key:examPartialResult.id,attrs:{"is-classic-score":_vm.assessmentType.showClassicScore,"product":_vm.assessment.product,"result":examPartialResult}})})],2)],(_vm.isTourActive)?_c('TourAssessmentResults',{attrs:{"application":_vm.application},on:{"finish":function($event){return _vm.finishTour()},"previous":function($event){_vm.isPreviousButtonPressed = true},"skip":function($event){return _vm.skipTour()}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }