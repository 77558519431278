<template>
  <div>
    <div
      v-if="loading"
      class="assessment__results --loading"
    >
      <Spinner />
    </div>

    <template v-else>
      <transition-group
        v-if="isResultReleased && hasAnyResult"
        appear
        class="assessment__results assessment__results--final"
        name="card"
        tag="div"
      >
        <AssessmentResultsExamFinal
          v-for="examFinalResult in examFinalResults"
          :key="examFinalResult.id"
          :color="examFinalResult.color"
          :image="examFinalResult.image"
          :title="examFinalResult.name"
          @click.native="$router.push({
            name: 'examResultGeneral',
            params: {
              applicationId,
              assessmentId,
              examId: examFinalResult.id,
            },
          })"
        />
      </transition-group>

      <transition-group
        v-else
        appear
        class="assessment__results"
        name="fade"
        tag="div"
      >
        <div
          v-for="exam in examsWithoutResults"
          id="v-step-3"
          :key="exam.id"
        >
          <AssessmentResultsExamEmpty
            :exam="exam"
            @click="showExamModal(exam)"
          />
        </div>

        <AssessmentResultsExamPartial
          v-for="examPartialResult in examPartialResults"
          :key="examPartialResult.id"
          :is-classic-score="assessmentType.showClassicScore"
          :product="assessment.product"
          :result="examPartialResult"
        />
      </transition-group>
    </template>

    <TourAssessmentResults
      v-if="isTourActive"
      :application="application"
      @finish="finishTour()"
      @previous="isPreviousButtonPressed = true"
      @skip="skipTour()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import subjects from '@/subjects'

const AssessmentResultsExamPartial = () => import('@/components/AssessmentResultsExamPartial')
const AssessmentResultsExamFinal = () => import('@/components/AssessmentResultsExamFinal')
const AssessmentResultsExamEmpty = () => import('@/components/AssessmentResultsExamEmpty')
const TourAssessmentResults = () => import('@/components/TourAssessmentResults')

export default {
  name: 'AssessmentResults',

  components: {
    AssessmentResultsExamPartial,
    AssessmentResultsExamFinal,
    AssessmentResultsExamEmpty,
    TourAssessmentResults,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => vm.$emit('select-tab', vm.$route.name))
  },

  props: {
    applicationId: {
      type: [String, Number],
      required: true,
    },

    assessmentId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isModalVisible: false,
      isPreviousButtonPressed: false,
      isNextButtonPressed: false,
      exam: null,
      event: {
        category: 'Avaliação',
        action: 'DesempenhoParcial',
        label: '',
      },
    }
  },

  computed: {
    ...mapGetters([
      'onboarding',
      'application',
      'exams',
      'partialResults',
      'assessmentType',
      'assessment',
    ]),

    isResultReleased() {
      return new Date(this.assessment.resultDate) <= new Date()
        && !(this.assessment.product === 'CORRECTOR')
    },

    resultDate() {
      return this.formatTime(this.assessment.resultDate)
    },

    examPartialResults() {
      const results = this.partialResults.map((result) => {
        const partialExam = this.exams.find((exam) => exam.id === result.examId)

        return {
          examName: partialExam.name,
          ...result,
          date: this.formatTime(this.assessment.resultDate),
        }
      })

      return results.sort(
        (a, b) => (a.applicationResponseId > b.applicationResponseId ? 1 : -1),
      )
    },

    examsWithResults() {
      const examIds = this.partialResults.map((result) => result.examId)

      return this.exams.filter((exam) => examIds.includes(exam.id))
    },

    examFinalResults() {
      return this.examsWithResults.map((exam) => {
        let examSubject = subjects.find((subject) => subject.name === exam.name)

        if (!examSubject) {
          examSubject = {
            image: '/img/portugues.svg',
          }
        }

        return {
          id: exam.id,
          name: exam.name,
          color: this.assessment.color,
          image: examSubject.image,
        }
      })
    },

    examsWithoutResults() {
      const examIds = this.partialResults.map((result) => result.examId)

      return this.exams.filter((exam) => !examIds.includes(exam.id))
    },

    hasAnyResult() {
      return this.examsWithoutResults.length < this.exams.length
    },

    isTourActive() {
      return this.onboarding.tourActive && !this.isLoading
    },
  },

  created() {
    this.$trackEvent(this.event)
    this.setOnboarding({
      ...this.onboarding,
      helpTourButtonPressed: false,
    })
    this.loadResults()
  },

  beforeDestroy() {
    const isTourButtonPressed = this.isNextButtonPressed
      || this.isPreviousButtonPressed
      || this.onboarding.helpTourButtonPressed
    if (this.isTourActive && !isTourButtonPressed) {
      this.setOnboarding({
        ...this.onboarding,
        tourActive: false,
        helpActive: true,
        helpTourButtonPressed: false,
      })
      this.$tours.onboarding.stop()
    }
  },

  methods: {
    ...mapActions(['setOnboarding', 'startExam', 'getResults']),

    async loadResults() {
      this.loading = true
      try {
        await this.getResults(this.application)
      } catch (error) {
        this.$toasted.global.error({
          message: 'Erro ao tentar carregar os resultados da avaliação.',
        })
      } finally {
        this.loading = false
      }
    },

    formatTime(time) {
      return this.$moment(time).format('L')
    },

    showExamModal(exam) {
      this.exam = exam
      this.isModalVisible = true
    },

    async start(exam) {
      try {
        await this.startExam({
          applicationId: this.application.id,
          examId: exam.id,
        })

        this.goToExam(exam)
      } finally {
        this.hideExamModal()
      }
    },

    goToExam(exam) {
      this.$router.replace({
        name: 'exam',
        params: {
          assessmentId: this.assessmentId,
          examId: exam.id,
          itemOrder: 0,
        },
      })
    },

    hideExamModal() {
      this.isModalVisible = false
    },

    skipTour() {
      this.setOnboarding({
        ...this.onboarding,
        tourActive: false,
        helpActive: false,
      })
    },

    finishTour() {
      this.setOnboarding({
        ...this.onboarding,
        tourActive: false,
        tourCompleted: true,
      })
    },
  },
}
</script>

<style lang="sass">

.assessment__results
  padding: 64px 0px
  width: 100%
  box-sizing: border-box
  display: grid
  grid-gap: 32px

  +mq-m
    padding: 32px 0

  +mq-s
    padding: 24px 0
    grid-gap: 24px

  &--final
    grid-template-columns: repeat(3, 1fr)

    +mq-m
      grid-template-columns: repeat(2, 1fr)
      padding: 32px 0

    +mq-s
      grid-template-columns: repeat(1, 1fr)
      padding: 24px 0
      grid-gap: 24px

  &.--loading
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: calc(100vh - 56px)
</style>
