const subjects = [
  {
    name: 'Matemática',
    image: '/img/matematica.svg',
    color: '#5196D6',
  },
  {
    name: 'Língua Portuguesa',
    image: '/img/portugues.svg',
    color: '#F86F6F',
  },
  {
    name: 'Multidisciplinar',
    image: '/img/portugues.svg',
    color: '#F86F6F',
  },
  {
    name: 'SAS Educação Fuvest',
    image: '/img/portugues.svg',
    color: '#F86F6F',
  },
]

export default subjects
